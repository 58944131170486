import Vue from 'vue'
import axios from 'axios'
import Store from '../store'
import Router from '../router'
import { Message,Loading } from 'element-ui';
import Utils from "./utils"
import apiConfig from "./requestConfig"

let loadingInstance; //loading 实例
let needLoadingRequestCount = 0; //当前正在请求的数量
function showLoading() {
  let main = document.querySelector('body')
  if (main) {
     if (needLoadingRequestCount === 0 && !loadingInstance) {
        loadingInstance = Loading.service({ 
          target: main, text: '正在加载...', background: 'rgba(0,0,0,0.1)' 
        });
        // loadingInstance = Loading.service();
     }
     needLoadingRequestCount++;
  }
}
function closeLoading() {
  Vue.nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
     needLoadingRequestCount--;
     needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); // 保证大于等于0
     if (needLoadingRequestCount === 0) {
        if (loadingInstance) {
          hideLoading()
        }
     }
  });
}
let hideLoading = Utils.debounce(() => {  // 防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
  loadingInstance.close();
  loadingInstance = null;
}, 300);

const errorHandle = (status, other) => {
  switch (status) {
    case 401:  // 401: 未登录状态，跳转登录页
      Router.replace({path: '/Login', query: {redirect: Router.currentRoute.fullPath}})   
      break;
    case 403:  // 403 token过期,清除token并跳转登录页
      Message.error({message:"登录过期，请重新登录"})  
      Store.commit('storeClearUserInfo');
      setTimeout( () => {Router.replace('/Login')} , 1000);
      break;
    case 404:   // 404请求不存在
      Message.error({message:"请求的资源不存在"})  
      break;
    default:
      console.log(other);   
    }
}

const serve = axios.create()
serve.interceptors.request.use(
  config => {
    showLoading()
    /* const token = Store.state.userInfo.token
    if (token ) { // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.token = token
    } */
    return config
  },
  err => {
    closeLoading()
    return Promise.reject(err);
  }
)
serve.interceptors.response.use( // 响应拦截
  res => {
    const {code,msg,data}=res.data
    closeLoading()
    if (code == 1 || code == 1011) return data // 登录成功数据或者登录失败的错误信息
    else {
      Message.error({message:msg})  // 其他未知错误
      console.error(`${res.config.url}接口错误值=> 错误码code:${code},错误信息msg:${msg}`)
    }
  },
  err => {
    const { response } = err;
    closeLoading()
    if (response) {
      errorHandle(response.status, response.data.message);   // 请求已发出，但是不在2xx的范围 
      return Promise.reject(err)
    }else{
      if (!window.navigator.onLine)  Message.error({message:"断网了，无法请求到数据！",duration:5000})
      else {
        Message.error({message:err+"，请稍候在尝试刷新页面！",duration:5000})
        return Promise.reject(err)
      }
    }
     
  }
)

function request(method, url, data , isFilePost=0) {
  method = method.toLocaleLowerCase();
  if( !Utils.checkPosUrl(url) ){ // 判断接口地址为相对还是绝对
    // data.sign=Utils.md5Str(Utils.simpleQsStr( Utils.asciiSortRmoveEmpty(data) )+'&appsecret=ec19870703a11c3d71aey36b730fz0e5'); // 签名
    data = Utils.qsStr( data , { allowDots: true }); // 将请求参数拼接&
    url = apiConfig.baseUrl + url;
  }
  if (method === 'post') {
    if(isFilePost){  // 文件上传
      return serve.post(url, data, {headers: {'Content-Type': 'multipart/form-data'}})
    }
    return serve.post(url, data, {headers: {'Content-type': 'application/x-www-form-urlencoded;charset=utf-8'}})
  } else if (method === 'get') {
    return serve.get(url, { params: data })
  } else if (method === 'delete') {
    return serve.delete(url, { params: data })
  }
}

export {
  axios,
  request
}



