import { request } from '../lib/request'
import Store from "../store"

export default {
    login(obj = {}) {  // 用户登录
        return request('post', 'api/user/login', {
            account: obj.account,
            password: obj.password
        })
    },
    loginOut() {  // 用户退出
        return request('post', 'api/user/logout', {
            token: Store.state.userInfo.token
        })
    },
    getDataBySide() {  // 侧边栏导航
        return request('post', 'api/index/getBaseInfo', {
            token: Store.state.userInfo.token
        })
    },
    getDataByCat(obj = {}) {  // 按分类获取数据
        return request('post', 'api/data/getByCat', {
            token: Store.state.userInfo.token,
            nationid: obj.nationid || 2, // 国家ID
            categorys: obj.categorys || 0,
            terms_type: obj.terms_type || 3,
            date_num: obj.date_num || 0,
            data_type: obj.data_type || 1,
            roe: obj.roe || 1
        })
    },
    getDataByArea(obj = {}) {  // 按地区获取数据
        return request('post', 'api/data/getByArea', {
            token: Store.state.userInfo.token,
            nationid: obj.nationid || 2,
            areas: obj.areas || 0,
            terms_type: obj.terms_type || 3,
            date_num: obj.date_num || 0,
            data_type: obj.data_type || 1,
            roe: obj.roe || 1
        })
    },
    getDataIndex(obj = {}) {  // 大屏数据
        return request('post', 'api/index', {
            nationid: obj.nationid || 2,
            areas: obj.areas || 0
        })
    },
    getReportList(obj = {}) {  // 智能报告
        return request('post', 'api/report/index', {
            token: Store.state.userInfo.token,
            nationid: obj.nationid || 2,
            type: obj.type || 0,           
            keyword: obj.keyword || '',
        })
    },
    getAddBefore(obj = {}) {  //  数据生成前置方法
        obj.token = Store.state.userInfo.token;
        return request('post', 'api/report/addBefore', obj)
    },
    addData(obj = {}) {  //  数据生成方法        
        obj.token = Store.state.userInfo.token;
        return request('post', 'api/report/add', obj)
    },
    getDetail(obj = {}) {  //  数据生成方法        
        obj.token = Store.state.userInfo.token;       
        return request('post', 'api/report/detail', obj)
    },
    reportDel(obj = {}) {  //  删除方法        
        obj.token = Store.state.userInfo.token;       
        return request('post', 'api/report/del', obj)
    }
}