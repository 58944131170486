import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

Vue.config.productionTip = false

// 全局引入图表
import echarts from 'echarts' 
Vue.prototype.$echarts = echarts 

// 全局引入element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);

import "./assets/css/reset.css"  // 重置样式
import "./assets/reportIcons/iconfont1.css"  // 重置样式

import utils from './lib/utils'
Vue.prototype.$utils = utils

import api from './api'  // 接口
Vue.prototype.$reqApi = api

import Print from 'vue-print-nb'
// Global instruction 
Vue.use(Print);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
