export default [
    {
        path: "/ReportData",  // 数据查询页面
        name: 'ReportData',
        component: () => import('@/pages/ReportData.vue'),
        meta: { needLogin: true }
    },
    {
        path: "/ReportSmart",  // 智能报告页面
        name: 'ReportSmart',
        component: () => import('@/pages/ReportSmart.vue'),
        meta: { needLogin: true }
    },
    {
        path: "/ReportDetail",  // 智能报告页面
        name: 'ReportDetail',
        component: () => import('@/pages/ReportDetail.vue'),
        meta: { needLogin: true }
    }
]