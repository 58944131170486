export default [
  {
    path: "/", // 首页
    alias: "/index",
    name: "Index",
    component: () => import("@/pages/Index.vue"),
    meta: {
      title: "南亚东南亚建设工程材料及设备价格波动监测",
      needLogin: false,
    },
  },
  {
    path: "/home", // 监测数据
    name: "Home",
    component: () => import("@/pages/Home.vue"),
    meta: { needLogin: false },
  },
  {
    path: "/login", // 登录页
    name: "Login",
    component: () => import("@/pages/Login.vue"),
    meta: { needLogin: false },
  },
  {
    path: "*", // 404页面直接跳首页
    // component: () => import('@/pages/Error.vue'),
    // meta: {needLogin:false}
    redirect: "/",
  },
];
