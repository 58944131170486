import QS from "qs"
export default {
  awaitFn(promise) {
    return promise.then(data => [null, data]).catch(err => [err, null])
  },
  checkPosUrl(url) { // 判断url是否为绝对路径
    return /(http|https):\/\/([\w.]+\/?)\S*/.test(url)
  },
  qsStr(obj,opt={}){  //QS库转字符拼接
    return QS.stringify( obj , opt)
  },
  debounce(func, wait, immediate) {
    let timeout
    return function(...args) {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        timeout = null
        if (!immediate) func.apply(this, args)
      }, wait)
      if (immediate && !timeout) func.apply(this, [...args])
    }
  },
  filterStr(str){
    return str.replace(/[（）a-zA-Z]+/g,"")
  },
  formatDate(str,format='{0}年{1}月{2}日 {3}时{4}分{5}秒') {
    let ary = str.match(/\d+/g)
    format = format.replace(/\{(\d+)\}/g, (...arg) => {
        var value = ary[arg[1]] || '0';
        // value.length < 2 ? value = '0' + value : null;
        return value;
    });
    return format;
  }
}
