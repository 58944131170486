import Vue from "vue"
import VueRouter from "vue-router"
import Store from "@/store";

import publicRouter from "./publicRouter"
import reportRouter from "./reportRouter"

Vue.use(VueRouter);
const router=new VueRouter({   
    routes:[
        ...reportRouter,  // 数据查询模块
        ...publicRouter,  // 公用路由配置
    ]
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {  // 路由发生变化修改页面title
      document.title = to.meta.title
    }
    if(to.meta.needLogin){ // 需要登录后才能访问
      if( Object.keys(Store.state.userInfo).length ){ // 已登录
        next()
      }else{ //未登录
        next('/login')
        router.app.$message.warning('请登录后重试！')        
      }
    }else{ // 不需要登录访问
      next()
    }    
  })

export default router;