import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    nationid: 2, // 默认国家
    userInfo: {}, // 用户信息
    sideCatItem: {}, // 保存侧边栏点击项
    selectTimeOptions: {}, // 保存年度、季度、月度数据等下拉项
  },
  mutations: {
    // 设置默认国家
    setNationid(state, info) {
      state.nationid = info;
    },
    storeSaveUserInfo(state, info) {
      // 保存用户信息
      state.userInfo = info;
    },
    storeClearUserInfo(state) {
      // 清除用户信息
      state.userInfo = {};
    },
    storeSaveSideCatItem(state, data) {
      // 保存侧边栏点击项
      state.sideCatItem = data;
    },
    storeSaveOptions(state, data) {
      // 保存年度、季度、月度数据等下拉项
      state.selectTimeOptions = data;
    },
  },
  actions: {},
  modules: {},
  plugins: [
    (store) => {
      if (sessionStorage.getItem("store")) {
        store.replaceState(Object.assign({}, store.state, JSON.parse(sessionStorage.getItem("store"))));
        //sessionStorage.removeItem("store")
      }
      window.addEventListener("beforeunload", () => {
        sessionStorage.setItem("store", JSON.stringify(store.state));
      });
    },
  ],
});
